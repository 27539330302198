// General: Reset List Styles
nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}


// Meta Navigation
.metanav {
  position: relative;
  background: $metanav-color;
  height: rem(40px);
  z-index: 100;
}
.metanav__container,
.metanav__row,
.metanav__col {
  height: 100%;
}
.metanav__list {
  position: relative;
  float: right;
  top: 50%;
  transform: translateY(-50%);
}
.metanav__listitem {
  float: left;
}
.metanav__listitem-link {
  color: #FFF;
  margin-left: rem(15px);
}
.metanav__listitem-link:hover {
  color: #FFF;
}
.metanav__listitem-link--icon {
  display: block;
  font-size: 0;
  width: rem(20px);
  height: rem(20px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: rem(15px);
  transition: all 0.3s;
}

.metanav__listitem-link--icon:hover {
  transform: scale(1.1);
}

.metanav.metanav--textonly .metanav__listitem-link--icon {
  font-size: rem(16px);
  width: auto;
  height: auto;
  background: none!important;
}

.metanav.metanav--textonly .metanav__listitem-link--icon:hover {
  transform: none;
}

.metanav:not(.metanav--textonly) .underline a:before {
  content: none;
}

.mainnav {
  position: fixed;
  width: 100%;
  height: rem(60px);
  background: $theme-primary;
  z-index: 100;
}

.mainnav.mainnav--transparent {
  background: transparentize($theme-primary, 0.2);
}

.mainnav__logo {
  width: rem(220px);
  height: rem(60px);
  float: left;
  padding: rem(10px 0);
  z-index: 9999;
}

.mainnav__logo figure {
  max-width: 100%;
  max-height: 100%
}

.mainnav__logo figure img {
  max-width: 100%;
  max-height: rem(40px);
}

.mainnav__menu-icon {
  float: right;
  top: rem(8px);
  z-index: 9999;
}

.mainnav__list1 {
  display: none;
  background: $theme-primary;
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  z-index: 9998;
  overflow-y: auto;
  border-top: rem(1px) solid rgba(255,255,255,0.3)
}

.mainnav__list2 {
  display: none;
  background: lighten($theme-primary,5%);
}

.mainnav__list3 {
  display: none;
  background: lighten($theme-primary,10%);
}

.mainnav__listitem1 {
  position: relative;
}

.mainnav__listitem1-link {
  display: block;
  position: relative;
  padding: rem(8px 10px);
  border-bottom: rem(1px) solid rgba(255,255,255,0.2);

}

.mainnav__listitem1-link {
  font-size: rem(20px);
  color: $text-color-light;
}

.mainnav__listitem1-link:hover,
.mainnav__listitem1-link:focus,
.mainnav__listitem1-link:active {
  color: #FFF;
  background: rgba(255,255,255,0.1);
}

.icon-unfold {
  position: absolute;
  right: 0;
  top: 0;
  width: rem(64px);
  height: rem(44px);
  z-index: 1000;
  cursor: pointer;
  text-align: center;
  border-left: rem(1px) solid rgba(255,255,255,0.5);
}

.icon-unfold:before {
  display: block;
  font-family: FontAwesome;
  content: "\f107";
  color: #fff;
  font-size: rem(32px);
  transition: transform 0.3s;
}

.icon-unfold.icon-unfold--down:before {
  transform: rotate(180deg);
}

.mainnav__listitem2 {
  position: relative;
}

.mainnav__listitem2-link:hover,
.mainnav__listitem2-link:focus,
.mainnav__listitem2-link:active {
  color: #FFF!important;
  background: rgba(181, 12, 81,1);
}

.mainnav__listitem2-link {
  display: block;
  padding: rem(5px 10px 5px 20px);
  color: $text-color-light;
  border-bottom: rem(1px) solid rgba(255,255,255,0.5);
}

.mainnav__list2 .icon-unfold {
  height: rem(38px);
}

.mainnav__list2 .icon-unfold:before {
  font-size: rem(26px);
}

.mainnav__listitem3-link {
  display: block;
  padding: rem(8px 10px 8px 30px);
  color: $text-color-light;
  border-bottom: rem(1px) solid rgba(255,255,255,0.5);
}

.mainnav__listitem3-link:hover,
.mainnav__listitem3-link:focus,
.mainnav__listitem3-link:active {
  color: #FFF;
  background: rgba(255,255,255,0.1);
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .mainnav {
    position: relative;
    height: rem(100px);
    margin-top: 0;
  }

  .mainnav > .container {
    position: relative;
    height: 100%;
  }

  .mainnav__list1 {
    left: 50%;
    top: rem(100px);
    border: none;
  }

  .mainnav__logo {
    height: 100%;
    padding: rem(20px 0);
  }

  .mainnav__logo figure {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .mainnav__logo figure img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: auto;
    max-height: rem(60px);
  }

  .mainnav__menu-icon {
    top: rem(45px);
  }

}



// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  header {
    height: rem(200px);
  }

  .container {
    width: 980px;
  }

  .mainnav {
    height: rem(155px);
  }

  .mainnav__logo {
    width: rem(200px);
    padding: rem(35px 0);
  }

  .mainnav__logo figure img {
    max-height: rem(140px);
    max-width: rem(160px);
  }

  .mainnav__list1 {
    display: block!important;
    position: relative;
    top: rem(88px);
    left: 0;
    float: left;
    width: rem(740px);
    background: none;
    border: none;
    overflow: visible;
  }

  .mainnav__listitem1 {
    float: left;
    width: auto;
    margin-right: 15px;
  }

  .flexbox .mainnav__list1 {
    display: flex!important;
    justify-content: space-between;
  }

  .flexbox .mainnav__listitem1 {
    margin: 0;
    float: none;
  }

  .mainnav__listitem1-link {
    border: none;
    font-size: rem(20px);
    padding: rem(11px 0 30px 0);
  }

  .mainnav__listitem1-link:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    left: 50%;
    height: rem(5px);
    bottom: 0;
    background: #FFF;
    transition: all 0.3s;
  }

  .mainnav__listitem1-link:hover {
    background: none;
  }

  .mainnav__listitem1:hover .mainnav__listitem1-link:after {
    width: 100%;
    left: 0;
  }

  .mainnav__listitem1-link.active:after {
    width: 100%;
    left: 0;
  }

  .mainnav__listitem1:hover .mainnav__list2 {
    display: block;
    position: absolute;
    top: rem(63px);
    left: 0;
    background: $theme-primary;
    width: rem(275px);
    padding: rem(20px 0 10px 0);
  }

  .mainnav__listitem2-link {
    border: none;
  }

  .fixed .mainnav {
    position: fixed;
    height: rem(80px);
    top: rem(-80px);
    margin-top: rem(80px);
    z-index: 1000;
  }

  .fixed .mainnav.mainnav--transparent {
    background: $theme-primary;
  }

  .fixed .mainnav__list1 {
    top: rem(22px);
  }

  .fixed .mainnav__listitem1-link {
    padding: rem(5px 0 25px 0);
  }

  .fixed .mainnav__listitem1:hover .mainnav__list2 {
    top: (58px);
  }

  .fixed .mainnav__logo {
    padding-bottom: rem(10px);
  }

  .fixed .mainnav__logo figure img {
    max-height: rem(60px);
  }

  .mainnav__listitem1-link:hover,
  .mainnav__listitem1-link:focus,
  .mainnav__listitem1-link:active {
    color: #FFF;
    background: rgba(255,255,255,0.0);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  header {
    height: rem(220px);
  }

  .mainnav {
    height: rem(180px);
  }

  .mainnav__logo {
    width: rem(250px);
    padding: rem(35px 0);
  }

  .mainnav__logo figure img {
    max-width: rem(180px);
  }

  .mainnav__list1 {
    width: rem(850px);
    margin-left: rem(30px);
    top: rem(113px);
  }

}