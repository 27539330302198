footer {
  position: relative;
  background: $footer-color;
}

footer * {
  color: #FFF!important;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__container {
  position: relative;
}

.footer__scroll2top {
  display: block;
  position: relative;
  left: 50%;
  margin: rem(20px 0 20px -22px);
  width: rem(44px);
  height: rem(44px);
  border: rem(2px) solid #FFF;
  text-align: center;
  font-size: rem(24px);
}

.footer__logo {
  width: 100%;
  min-height: rem(44px);
  margin: rem(40px 0 30px 0);
  text-align: center;
}

.footer__logo figure {
  width: 75%;
  max-width: rem(170px);
  margin: 0 auto;
}

.footer__logo img {
  max-width: 100%;
}

.footer__text {
  text-align: center;
  margin: rem(20px 0);
}

.footer__contact-listitem {
  width: 100%;
  text-align: center;
  margin-bottom: rem(10px);
}

.footer__contact-listitem p {
  display: inline;
}

.footer__contact-icon {
  position: relative;
  display: inline-block;
  width: rem(32px);
  height: rem(32px);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  top: rem(10px);
  margin-right: rem(10px);
}

.footer__metanav {
  background: $footer-metanav-color;
  padding: rem(10px);
  margin-top: rem(20px);
  height: rem(50px);
}

.footer__metanav-list {
  float: right;
}

.footer__metanav-listitem {
  float: left;
  margin-left: 20px;
}

.flexbox .footer__metanav-list {
  display: flex;
  position: relative;
  justify-content: space-around;
  float: none;
  top: 50%;
  transform: translateY(-50%);
}

.flexbox .footer__metanav-listitem {
  float: none;
  margin: 0;
}

.footer__metanav-container {
  height: 100%;
}

.footer__text {
  display: none;
}

.footer--infotext .footer__text {
  display: block;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .footer__logo {
    margin: rem(60px 0 50px 0);
  }

  .footer__contact-listitem {
    width: 33.3333%;
    float: left;
  }

  .flexbox .footer__contact-list {
    display: flex;
    justify-content: center;
    margin-bottom: rem(20px);
  }

  .flexbox .footer__contact-listitem {
    float: none;
    width: auto;
    padding: rem(0 20px);
  }

  .flexbox .footer__metanav-list {
    justify-content: center;
  }

  .flexbox .footer__metanav-listitem {
    padding: rem(0 10px);
  }

  .footer--infotext .footer__scroll2top,
  .footer--inline .footer__scroll2top {
    position: absolute;
    right: rem(20px);
    left: auto;
    margin: rem(20px 0 0 0);
  }

  .footer--infotext .footer__logo,
  .footer--inline .footer__logo {
    width: rem(220px);
    margin: rem(20px 0 0 0);
  }

  .footer--infotext .footer__logo {
    float: left;
    width: rem(220px);
  }

  .footer--infotext .footer__logo figure,
  .footer--inline .footer__logo figure {
    width: 100%;
    max-width: none;
  }

  .footer--inline .footer__contact-list {
    margin: rem(30px 0 0 0);
  }

  .flexbox .footer--inline .footer__contact-list {
    justify-content: space-between;
  }

  .flexbox .footer--inline .footer__contact-listitem {
    padding: 0;
  }

  .flexbox .footer--infotext .footer__metanav-list,
  .flexbox .footer--inline .footer__metanav-list {
    justify-content: flex-end;
  }

  .flexbox .footer--infotext .footer__metanav-listitem,
  .flexbox .footer--inline .footer__metanav-listitem {
    padding: 0;
    margin: rem(0 0 0 20px);
  }

  .footer--infotext .footer__text {
    text-align: left;
    margin-top: rem(50px);
    width: rem(400px);
    float: left;
  }

  .footer--infotext .footer__logo {
    margin: rem(50px 50px 0 0);
  }

  .footer--infotext .footer__contact {
    float: left;
    margin-top: rem(10px);
    width: 100%;
  }

  .flexbox .footer--infotext .footer__contact-list {
    justify-content: space-between;
  }

  .flexbox .footer--infotext .footer__contact-listitem {
    padding: 0;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .footer--inline .footer__logo {
    float: left;
    margin: rem( 40px 100px 20px 0);
  }

  .footer--inline .footer__contact-list {
    margin-top: rem(80px);
  }

  .flexbox .footer--inline .footer__contact-list {
    justify-content: flex-end;
  }

  .flexbox .footer--inline .footer__contact-listitem {
    margin-left: rem(40px);
  }

  .footer--inline .footer__contact-list {
    margin-top: rem(80px);
  }

  .footer--infotext .footer__contact-list {
    padding-left: rem(270px);
  }

  .footer--infotext .footer__text {
    width: rem(500px);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .footer__contact-listitem {
    font-size: rem(20px);
  }

  .footer__contact-icon {
    width: rem(44px);
    height: rem(44px);
    top: rem(12px);
    margin-right: rem(15px);
  }

  .footer--inline .footer__contact-list {
    margin-top: rem(67px);
  }

  .footer--infotext .footer__text {
    width: rem(330px);
    margin: rem(120px 100px 0 0);
  }

  .footer--infotext .footer__contact {
    width: rem(350px);
    margin-top: rem(94px);
  }

  .footer--infotext .footer__contact-list {
    display: block;
    padding: 0;
  }

  .footer--infotext .footer__contact-listitem {
    float: none;
    text-align: left;
  }

  .footer--infotext .footer__logo {
    width: rem(300px);
  }

}