.cards {
  padding: rem(50px 0);
}

.cards__item {
  position: relative;
  display: block;
  margin-bottom: rem(50px);
}

.cards__item-image {
  display: block;
  width: 100%;
  height: 200px;
  height: 60vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: rem(15px);
}

.cards__item-headline {
  display: block;
  font-size: $font-size-h4;
  color: $text-color-dark;
  font-weight: bold;
  margin-bottom: rem(10px);
  padding: rem(0 10px);
}

.cards__item-text {
  display: block;
  font-size: $font-size-base;
  color: $text-color-dark;
  margin-bottom: rem(15px);
  padding: rem(0 10px);
}

.cards__item-btn {
  display: block;
  border-color: $theme-secondary;
  color: $theme-secondary;
  font-weight: 400;
  max-width: none!important;
  width: 100%;
}

.cards__item:hover .cards__item-btn {
  background: $theme-secondary;
  color: #FFF;
}

.cards .slick-dots {
  bottom: rem(-20px);
}

.cards .slick-dots li button {
  border-color: $theme-secondary;
  transform: scale(0.75);
  margin: rem(0 2.5px);
}

.cards .slick-dots li button:after {
  background-color: $theme-secondary;
}

.cards.cards--bgcolor .slick-dots li button {
  border-color: #fff;
}

.cards.cards--bgcolor .slick-dots li button:after {
  background-color: #fff;
}

.cards.cards--bgcolor {
  background-color: $theme-secondary;
}

.cards.cards--bgcolor .cards__item {
  background-color: #fff;
  padding: rem(20px 0 0 0);
  text-align: center;
}

.cards.cards--bgcolor .cards__item-headline {
  padding: rem(20px 20px 10px 20px);
}

.cards.cards--bgcolor .cards__item-text {
  border-bottom: rem(10px) solid $theme-secondary;
  padding: rem(0px 20px 50px 20px);
  margin: 0;
}

.cards.cards--bgcolor .cards__item-btn {
  background: $theme-secondary;
  color: #FFF;
}

.cards.cards--bgcolor .cards__item:hover .cards__item-btn {
  background: #fff;
  color: $theme-secondary;
  border-color: #fff;
}

.cards__intro {
  display: none;
}

.cards.cards--hasintro .cards__intro {
  display: block;
  text-align: center;
  margin-bottom: rem(50px);
}

.cards.cards--hasintro.cards--bgcolor .cards__intro * {
  color: #FFF;
}

.cards.cards--hasicons .cards__item {
  text-align: center;
  padding-top: 20px;
}

.cards.cards--hasicons .cards__item-image {
  position: relative;
  background-size: contain;
  height: rem(80px);
  margin-bottom: rem(40px);
}

.cards.cards--hasicons .cards__item-image:after {
  content: "";
  display: block;
  position: absolute;
  bottom: rem(-20px);
  width: 100%;
  height: rem(2px);
  background-color: $theme-secondary;
  transition: all 0.3s;
}

.cards.cards--hasicons.cards--bgcolor .cards__item {
  background-color: transparent;
}

.cards.cards--hasicons.cards--bgcolor .cards__item * {
  color: #FFF;
}

.cards.cards--hasicons.cards--bgcolor .cards__item-headline {
  padding: 0;
}

.cards.cards--hasicons.cards--bgcolor .cards__item-text {
  padding: rem(0 0 10px 0);
}

.cards.cards--hasicons.cards--bgcolor .cards__item-image:after {
  background-color: #fff;
}

.cards.cards--hasicons .cards__item:hover .cards__item-image:after {
  //bottom: rem(100px);
}

.cards.cards--hasicons .cards__item:hover .cards__item-btn {
  border-color: $theme-secondary;
}

.cards.cards--hasicons .cards__item:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: rem(2px);
  height: 0;
  background-color: $theme-secondary;
  transition: all 0.3s;
}

.cards.cards--hasicons .cards__item:hover:before {
  //height: 100%;
}

.cards.cards--hasicons .cards__item:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: rem(2px);
  height: 0;
  background-color: $theme-secondary;
  transition: all 0.3s;
}

.cards.cards--hasicons:hover .cards__item:hover:after {
  //height: 100%;
}

.cards.cards--hasicons.cards--bgcolor .cards__item:before {
  background-color: #FFF;
}

.cards.cards--hasicons.cards--bgcolor .cards__item:after {
  background-color: #FFF;
}

.cards.cards--hasicons.cards--bgcolor .cards__item:hover .cards__item-btn {
  border-color: #FFF;
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .cards {
    padding: rem(75px 0 15px 0);
  }

  .cards__item {
    margin-bottom: rem(60px);
  }

  .cards__item-image {
    height: rem(230px);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .cards {
    padding: rem(100px 0 25px 0);
  }

  .cards__item {
    margin-bottom: rem(75px);
  }

  .cards__item-image {
    height: rem(190px);
  }

  .cards .slick-dots li button {
    transform: scale(1);
    margin: rem(0 7.5px);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .cards {
    padding: rem(120px 0 20px 0);
  }

  .cards__item {
    margin-bottom: rem(100px);
  }

  .cards__item-image {
    height: rem(230px);
    margin-bottom: rem(30px);
  }

  .cards__item-headline {
    margin-bottom: rem(20px);
  }

  .cards__item-text {
    margin-bottom: rem(30px);
  }

  .cards.cards--bgcolor .cards__item-headline {
    padding: rem(10px 20px 0 20px);
  }

  .cards .slick-dots {
    bottom: rem(0px);
  }

  .cards.cards--hasicons .cards__item-image {
    height: rem(90px);
    margin-bottom: rem(50px);
  }

  .cards.cards--hasicons.cards--bgcolor .cards__item-text {
    padding: rem(0 0 30px 0);
  }

  .cards.cards--hasicons .cards__item:hover .cards__item-image:after {
    //bottom: rem(110px);
  }

}